<template>
  <el-card class="card-main">
    <div class="left">
      <div class="parent">
        <div class="child_div">
          <div v-for="(item1, index1) in projectDeviceList.devices" :class="{ 'active': active === index1 }"
            :key="index1" class="child" @click.stop="choose(item1, index1)">
            <div class="header">
              <span>{{ item1.deviceName }}</span>
              <span class="tag" :class="{ offline: item1.deviceStatus !== '1' }">{{ item1.deviceStatus === '1' ? '在线' :
                '离线' }}</span>
            </div>
            <div class="content">设备串号：{{ item1.deviceNumber }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="map"></div>
    <div class="drawer" :class="drawer ? 'drawerShow' : 'drawerHide'">
      <template v-if="drawerData">
        <div class="info">
          <p class="text">设备名称<span>{{ drawerData.deviceName || '--' }}</span></p>
          <p class="text">设备串号<span>{{ drawerData.deviceNumber || '--' }}</span></p>
          <p class="text">设备状态
            <span class="highlight" :class="drawerData.deviceStatus === '2' ? 'gray' : ''">
              {{ drawerData.deviceStatus === '1' ? '在线' : '离线' }}
            </span>
          </p>
          <p class="text">当前控制模式
            <span class="highlight" :class="lcEnable === '1' ? '' : 'gray'">
              {{ lcEnable === '1' ? '远程' : lcEnable === '0' ? '本地' : '- -' }}
            </span>
          </p>
          <p class="text" v-if="lcEnable === '0'">
            <span :class="lcEnable === '0' ? 'warn' : ''">
              本地控制模式下无法远程操作！请将控制模式改为远程！
            </span>
          </p>
        </div>
        <div class="line paramList">
          <div class="paramItem">
            <p class="ttl">运行状态</p>
            <p>{{ drawerData.operateCommand === '1' ? '开启' : drawerData.operateCommand === '0' ? '关闭' : '--' }}</p>
          </div>
          <div class="paramItem">
            <p class="ttl">远程控制</p>
            <p>
              <el-switch active-value="1" inactive-value="0" v-model="drawerData.operateCommand" @change="swicthControl"
                active-color="#7973ef" inactive-color="#333333" :disabled="lcEnable !== '1'">
              </el-switch>
            </p>
          </div>
        </div>
      </template>
    </div>

    <durationDialog :visible.sync="showDurationDialog" :deviceId="this.$route.query.projectId"
      @confirm="handleDurationConfirm" @cancel="handleDialogCancel">
    </durationDialog>
  </el-card>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import durationDialog from './durationDialog.vue'
export default {
  name: 'DetailMap',
  components: {
    durationDialog
  },
  data() {
    return {
      drawer: false,
      map: null,
      ploygons: [],
      markers: [],
      onlineImgOpen: require('@/assets/icon/mistForest/open.svg'),
      onlineImgClose: require('@/assets/icon/mistForest/close.svg'),
      offImg: require('@/assets/icon/mistForest/offline.svg'),
      deviceList: [],
      drawerData: null,
      projectDeviceList: {},
      projectId: '',
      active: -1,
      projectLocation: [],
      activeName: 0,
      projectInfo: {},
      showDurationDialog: false,
      pendingCommand: null,
      lcEnable: '' || null
    }
  },
  created() {
    this.getData()
  },
  destroyed() {
    if (this.markers) {
      this.markers.forEach((item) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.clearMap()
    }
  },
  methods: {
    // 左侧项目列表
    getData() {
      this.$axios.get(this.$apis.mistForest.selectSpray, {
        projectId: this.$route.query.projectId
      }).then(res => {
        this.projectDeviceList = res || []
        console.log(this.projectDeviceList, '项目222')

        if (this.projectDeviceList.devices.length > 0) {
          this.projectId = res.projectId
          this.getPloygon()
        } else {
          this.$nextTick(() => {
            this.initMap(116.4017, 39.9)
          })
        }
      })
    },
    // 右侧项目地图数据
    getPloygon() {
      this.$axios.get(this.$apis.project.selectProjectAreaById, {
        projectId: this.projectId
      }).then((res) => {
        console.log(res, '区域')

        if (res) {
          this.projectLocation = res.projectLocation || []
          this.projectInfo = res.projectInfo || {}
          console.log(this.projectLocation, 'projectLocation')
          this.$nextTick(() => {
            this.initMap(Number(this.projectInfo.longitude), Number(this.projectInfo.latitude))
          })
        } else {
          this.$message.error('未获取到项目信息')
        }
      })
    },
    // 初始化地图
    initMap(lng, lat) {
      AMapLoader.load({
        key: mapKey,
        version: '2.0',
        plugins: []
      }).then(() => {
        const center = [lng, lat]
        const map = new AMap.Map('map', {
          center: center,
          zoom: 11
        })
        this.map = map
        this.ploygons = drawPolygon(this.map, this.projectLocation || [], null, this.drawerClose)
        this.getDeviceList()

        // 点击地图关闭抽屉
        this.map.on('click', this.drawerClose)
      })
    },

    // 获取设备信息
    getDeviceList() {
      this.$axios.get(this.$apis.mistForest.selectSprayPage, {
        projectId: this.projectId
      }).then((res) => {
        this.drawMarker(res.list)
        this.$forceUpdate()
      })
    },

    choose(row, index) {
      this.active = index
      if (this.map) {
        const e = {
          target: {}
        }
        if (row.longitude && row.latitude) {
          e.target = this.markers.find((item) => {
            return item.detail.deviceNumber === row.deviceNumber
          })
          this.map.setCenter([Number(row.longitude), Number(row.latitude)])
        } else {
          // this.iconTab()
          e.target = {
            detail: row
          }
        }
        this.drawerOpen(e)
      }
    },

    // 获取图片
    getIcon(deviceStatus, operateCommand, isCurrent) {
      if (!isCurrent) {
        return (deviceStatus === '1' ? (operateCommand === '1' ? this.onlineImgOpen : this.onlineImgClose) : this.offImg)
      }
    },

    // 地图打点
    drawMarker(list) {
      this.markers = []
      if (list && list.length) {
        // 循坏将设备marker添加到地图
        list.forEach((item) => {
          console.log(list, 'list')
          if (item.longitude && item.latitude) {
            const icon = this.getIcon(item.deviceStatus, item.operateCommand, false)
            const marker = new AMap.Marker({
              icon: new AMap.Icon({
                // 图标的取图地址
                image: icon
              }),
              offset: new AMap.Pixel(-18, -48),
              position: new AMap.LngLat(Number(item.longitude), Number(item.latitude)),
              extData: { ...item, type: 'icon' },
              label: {
                direction: 'top',
                content: "<div class='labelContent'>" + item.deviceName + '</div>',
                offset: new AMap.Pixel(-5, -10)
              }
            })
            marker.detail = item
            marker.on('click', this.drawerOpen)
            this.map && this.map.add(marker)
            this.markers.push(marker)
          }
        })
      }
    },

    // 抽屉打开
    async drawerOpen(e) {
      const info = e.target.getExtData()
      if (this.drawerData === null || (this.drawerData && this.drawerData.deviceNumber !== info.deviceNumber)) {
        if (this.drawerData && this.drawerData.deviceNumber !== info.deviceNumber) {
          this.drawerClose()
        }
        this.drawerData = await this.getDeviceDetail(info.deviceNumber)
        if (this.drawerData.latestReportData) {
          this.lcEnable = this.drawerData.latestReportData.lcEnable
        } else {
          this.lcEnable = null
        }
        console.log(this.drawerData, 'drawerData')
      }
      this.drawer = true
    },
    // 抽屉关闭
    drawerClose() {
      if (this.drawer) {
        this.drawer = false
        // 清空抽屉
        // this.iconTab()
        this.drawerData = null
      }
    },
    getDeviceDetail(row) {
      return this.projectDeviceList.devices.find((item) => item.deviceNumber === row)
    },
    // 开关控制/dialog开关
    swicthControl() {
      const command = this.drawerData.operateCommand
      if (command === '1') {
        this.showDurationDialog = true
        this.pendingCommand = command
      } else {
        this.executeSwitchOperation(command)
      }
    },
    // 处理dialog确认
    handleDurationConfirm(duration) {
      this.executeSwitchOperation(this.pendingCommand, duration)
    },

    // 处理dialog取消
    handleDialogCancel() {
      this.drawerData.operateCommand = '0'
    },

    // 更改雾森开关状态
    async executeSwitchOperation(command, duration = null) {
      const loading = this.$loading({
        lock: true,
        text: `雾森正在${command === '1' ? '开启' : '关闭'}...`,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const deviceId = this.drawerData.deviceId
      try {
        const params = {
          deviceId: deviceId,
          operateCommand: command
        }

        if (command === '1') {
          params.execLength = duration
        }

        await this.$axios.post(this.$apis.mistForest.insertSpray, params)
        this.$message.success(`${command === '1' ? '开启' : '关闭'}成功`)
        this.showDurationDialog = false
        this.iconTab()
      } catch (error) {
        this.$message.error(`${command === '1' ? '开启' : '关闭'}失败`)
        this.showDurationDialog = false
        this.drawerData.operateCommand = command === '1' ? '0' : '1'
      } finally {
        loading.close()
        this.pendingCommand = null
      }
    },
    // 替换图标
    iconTab() {
      if (this.map && this.drawerData) {
        const deviceNumber = this.drawerData.deviceNumber || ''
        const index = this.markers.findIndex((item) => item.detail.deviceNumber === deviceNumber)
        const icon = this.getIcon(this.drawerData.deviceStatus, this.drawerData.operateCommand, false)
        const marker = this.markers[index]
        if (this.drawerData.longitude && this.drawerData.latitude) {
          marker.setOffset(new AMap.Pixel(-18, -48))
          marker.setIcon(icon)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/color.scss';

.el-card {
  height: 100%;
  width: 100%;

  #map {
    width: calc(100% - 342px);
  }
}

::v-deep .el-card__body {
  position: relative;
  height: 100%;
  display: flex;
}

.left {
  width: 342px;
  height: 100%;
  background: #F6F7FB;
  border-radius: 2px;
  margin-right: 16px;
  padding: 6px;
  overflow: auto;

  .parent {
    width: 290px;
    margin-bottom: 10px;

    .header {
      height: 60px;
      background: #FFFFFF;
      padding: 8px 10px 8px;
      color: #000;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      cursor: pointer;

      .title {
        display: flex;
        justify-content: space-between;
      }

      .inline {
        display: flex;

        p {
          margin-right: 30px;
        }
      }

      .dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #9B9B9B;
        border-radius: 2px;
        margin-right: 4px;
      }

      .dot1 {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #7ED321;
        border-radius: 2px;
        margin-right: 4px;
      }
    }

    .tit {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
    }

    .cou {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
    }
  }

  .child_div {
    width: 278px;
    margin: 0 auto;
    margin-top: 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;

    .child {
      width: 100%;
      height: 88px;
      background: #FFFFFF;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      margin-bottom: 10px;
      cursor: pointer;

      &.active {
        border: 2px solid #1ad1b0;

        .header {
          background: rgba(26, 209, 176, .1) !important;
        }
      }

      .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 44px;
        background: #F6F7FB;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        text-align: left;
        padding: 11px 16px;
      }

      .tag {
        width: 44px;
        height: 20px;
        background: #1AD1B0;
        border-radius: 2px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.offline {
          background: #999999;
        }
      }

      .content {
        padding: 11px 16px;
      }
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  background: #FFFFFF;

  .online {
    width: 270px;
    height: 200px;
    position: absolute;
    left: 40px;
    top: 90px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 10;

    &>div {
      width: 100%;
      height: 100%;
    }
  }

  ::v-deep .amap-marker-label {
    border: 1px solid #fff;
  }
}

.drawer {
  position: absolute;
  right: -440px;
  top: 20px;
  z-index: 20;
  width: 380px;
  height: 400px;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-left: 4px solid $color-default;
  transition: all 0.4s;
  overflow: auto;

  .title {
    font-size: 16px;
    color: $color-font-dark;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .info {
    padding-bottom: 20px;

    .text {
      font-size: 14px;
      color: $color-font-dark;
      font-weight: 500;
      line-height: 30px;
      position: relative;

      span {
        margin-left: 10px;
        color: $color-font-default;
      }

      .highlight {
        display: inline-block;
        position: absolute;
        text-align: center;
        width: 44px;
        top: -50%;
        margin-top: 20px;
        background: $color-default;
        border-radius: 2px;
        color: #fff;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
      }

      .warn {
        color: #FF4D4F;
      }

      .gray {
        background: #B7B7B7;
      }
    }
  }

  .line {
    border-top: 1px solid #F1F1F1;
    padding-top: 20px;
  }

  .paramList {
    margin-bottom: 20px;

    .paramItem {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      gap: 10px;

      p {
        font-size: 14px;
        line-height: 24px;
        color: #333;

        &.ttl {
          width: 90px;
          color: #000;

          &.ttl-long {
            width: 94px;
          }
        }

        .large {
          font-family: 'DINAlternateBold';
          font-size: 24px;
          color: #52C41A;
          vertical-align: baseline;
          padding-right: 5px;
        }

        &.c-block {
          width: auto;
          height: 20px;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 18px;
          border-radius: 2px;
          padding: 0px 8px;
        }

        &.c-block.yellow {
          background: #FFC117;
        }

        &.c-block.green {
          background: #45B6AC;
        }

        &.c-block.blue {
          background: #1EA2FF;
        }

        &.c-red {
          color: #FF603B;
        }

        &.c-yellow {
          color: #FFC117;
        }

        &.c-green {
          color: #13C2C2;
        }

        &.c-danger {
          color: #FE3A2F;
        }
      }

      .el-progress {
        width: 160px;
        height: 8px;
        margin: 8px 5px 0 0;

        ::v-deep {

          .el-progress-bar__outer,
          .el-progress-bar__inner {
            border-radius: 1px;
          }
        }
      }

      .el-link {
        font-size: 12px;
        height: 20px;
        margin-left: 10px;
        border-bottom: 1px solid #1AD1B0;
      }
    }

    .paramItems {
      margin-bottom: 10px;

      p {
        font-size: 14px;
        line-height: 24px;
        color: #333;

        &.ttl {
          width: 66px;
          color: #000;

          &.ttl-long {
            width: 94px;
          }
        }
      }

      .timeDis {
        margin-left: 16px
      }
    }

    .paramError {
      display: flex;

      img {
        margin-right: 4px;
      }
    }
  }
}

.drawerShow {
  transform: translateX(-460px)
}

.drawerHide {
  transform: translateX(0px)
}

.btnControl {
  background-color: #2C8EFF;
  border-color: #2C8EFF;
}

::v-deep .amap-info-close {
  display: none;
}

::v-deep .amap-marker-label {
  border: 0 !important;

  .marker-label-content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }

  &::after {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-5px);
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    /* 三角形左边透明 */
    border-right: 5px solid transparent;
    /* 三角形右边透明 */
    border-top: 5px solid #FFFFFF;
    /* 三角形顶部为黑色，可以根据需要修改颜色 */
  }
}

::v-deep .labelContent {
  font-size: 14px;
  font-weight: bold;
  background: #ffffff;
}
</style>
