<!-- components/DurationDialog.vue -->
<template>
  <el-dialog :visible="visible" title="选择开启时长" width="30%" @close="handleClose">
    <el-select v-model="selectedDuration" placeholder="请选择开启时长" clearable>
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => [
        { value: 30, label: '30分钟' },
        { value: 60, label: '60分钟' },
        { value: 120, label: '120分钟' },
        { value: 150, label: '150分钟' }
      ]
    }
  },

  data() {
    return {
      selectedDuration: null
    }
  },

  methods: {
    handleConfirm() {
      if (!this.selectedDuration) {
        this.$message.warning('请选择开启时长')
        return
      }
      this.$emit('confirm', this.selectedDuration)
      this.clearSelection()
    },

    handleCancel() {
      this.$emit('cancel')
      this.clearSelection()
    },

    handleClose() {
      this.$emit('update:visible', false)
      this.clearSelection()
    },

    clearSelection() {
      this.selectedDuration = null
    }
  }
}
</script>
